
























import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import SectionComponent from '@/components/SectionComponent.vue';
import { Data } from '@/utils/types/WidgetData';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Component({
  inheritAttrs: false,
  components: {
    SectionComponent,
  },
})
export default class CompanyContactListWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: '' })
  protected readonly title!: string;

  @Prop({ required: false, default: '' })
  protected readonly subtitle!: string;

  @Prop({ required: false, default: '' })
  protected readonly anchor!: string;

  @Prop({ required: false, default: () => [] })
  protected readonly data!: Data[];

  private get slideCardSize(): number {
    switch (this.breakpoint.value) {
      case 'xl':
        return 350;
      case 'lg':
      case 'md':
        return 330;
      case 'sm':
      case 'xs':
        return 290;
      default:
        return 350;
    }
  }

  private get items(): Data[] {
    if (this.data && this.data.length > 0) {
      return this.data
        .filter((item) => (!!item.name || !!item.phone || !!item.fax || !!item.email))
        .map((item) => ({
          cardType: 'companyContact',
          payload: JSON.stringify({
            ...item,
            code: 'CompanyContactWidget',
          }),
        }));
    }
    return [];
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode) {
      const entityCode = WidgetHelper
        .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'exhibitorContact',
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.EXHIBITOR_CONTACT_FILTER,
            value: {
              exhibitor: {
                uid: entityCode,
              },
            },
          },
        }),
        fragmentName: 'exhibitorContactBaseFragment',
      }]);
    } else if (this.widget && !this.standAlone) {
      this.setDataConfig(
        undefined,
        true,
        `Missing entityCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }
}
